.force-login {
  .login-code {
    margin-top: 0.8rem;

    .code-button {
      height: 1.12rem;
      line-height: 1.12rem;
    }
  }
}
@primary-color: #007aff;