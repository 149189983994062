@inputFieldStyle: {
  width: 100%;
  //text-align: center;
  margin: auto;
}

@inputFieldInput: {
  width: 100%;
  height: 1.12rem;
  font-size: 0.32rem;
  color: rgba(0, 0, 0, 0.35);
  border: none;
  border-bottom: 0.02rem solid rgba(229, 229, 229, 1);
  outline: none;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

@inputFieldInputFocus: {
  color: rgba(0, 0, 0, 0.35);
  border-bottom: 0.02rem solid rgba(0, 122, 255, 1);
}

@inputFieldNoticeMessage: {
  width: 100%;
  text-align: right;
  height: 0.38rem;
  font-size: 0.28rem;
  color: rgba(0, 0, 0, 1);
  opacity: 0.55;
}

.login-control {
  @inputFieldStyle();

  .input-field {
    @inputFieldInput();

    &:focus {
      @inputFieldInputFocus();
    }
  }

  .notice-message {
    @inputFieldNoticeMessage();
  }

  &.error {
    .input-field {
      border-bottom: 0.02rem solid red;
    }

    .notice-message {
      color: red;
    }
  }
}
