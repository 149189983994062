.account-login-form {
  @import "Login";

  .account-login-box {
	margin: 0 0.8rem;
  }

  .login-button {
	margin-top: 0.36rem;
  }
}
@primary-color: #007aff;