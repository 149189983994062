html {
	font-size: 50px;
}

/*最大屏幕分辨率为1280px*/
@media screen and (max-width: 1280px) {
	html {
		font-size: calc(50px * 1280 / 1920);
	}
}

/*最小屏幕分辨率为1280px且最大为1440px*/
@media (min-width: 1280px) and (max-width: 1440px) {
	html {
		font-size: calc(50px * 1440 / 1920);
	}
}

/*最小屏幕分辨率为1440px且最大为1680px*/
@media (min-width: 1440px) and (max-width: 1680px) {
	html {
		font-size: calc(50px * 1680 / 1920);
	}
}

/*最小屏幕分辨率为1920px*/
@media screen and (min-width: 1920px) {
	html {
		font-size: 50px;
	}
}

body {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0px 1000px white inset;
	-webkit-text-fill-color: #333;
}

#root {
	width: 100%;
	height: 100%;
}
