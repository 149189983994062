.login-duration {
  @import "InputStyle";

  @inputFieldStyle();
  text-align: left;
  height: 0.38rem;
  font-size: 0.28rem;
  color: rgba(0, 0, 0, 1);
  opacity: 0.55;
  display: flex;
  align-items: center;

  input {
	width: 0.28rem;
	height: 0.28rem;
	border: 0.02rem solid rgba(204, 204, 204, 1);
	margin: 0.02rem;
	opacity: 1;
	cursor: pointer;
  }

  label {
	cursor: pointer;
  }
}