.loading-box {
  text-align: center;
  display: table;
  margin: 0 auto;
  height: 100%;

  .loading-content {
    display: table-cell;
    vertical-align: middle;
  }
}
@primary-color: #007aff;