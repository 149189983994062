@import "InputStyle";

.login-button {
  @inputFieldStyle();
  text-align: center;
  font-size: 0.32rem;
  color: rgba(255, 255, 255, 1);
  height: 1.12rem;
  background-color: rgba(0, 122, 255, 1);

  button {
    @inputFieldStyle();
    height: 1.12rem;
    background-color: rgba(0, 122, 255, 1);
    outline: none;
    border: none;
    cursor: pointer;

    &:disabled {
      background-color: #bbbbbb;
      cursor: not-allowed;

      .loading-spin {
        margin: auto 0.2rem;
      }
    }
  }
}