.mobile-login-form {
  @import "Login";

  .mobile-code-login-box {
	margin: 0 0.8rem;

	.login-code {
	  .code-button {
		height: 1.12rem;
	  }
	}
  }

  .login-button {
	margin-top: 0.36rem;
  }
}


@primary-color: #007aff;