.login-code {
  @import "InputStyle";

  .code-button {
	width: auto;
	font-size: 0.32rem;
	height: 1.12rem;
	position: absolute;
	//line-height: 1.12rem;
	transform: translateX(-100%);
	border: unset;
	background-color: unset;
	color: #007aff;
	cursor: pointer;
	outline: none;
	border: none;
	text-align: right;

	&.disabled,
	&[disabled] {
	  color: rgba(0, 0, 0, 0.35);
	  cursor: not-allowed;
	}

	&:focus,
	&:active,
	&:hover {
	  outline: none;
	  border: none;
	  background-color: unset;
	}
  }
}