.login-redirect {
  text-align: right;
  width: 100%;
  margin: 0.36rem auto auto;
  font-size: 0.28rem;

  input {
    cursor: pointer;
    margin: 0.02rem;
  }

  a,
  button {
    color: rgba(0, 0, 0, 1);
    opacity: 0.55;
    border: unset;
    background-color: unset;
    cursor: pointer;
    outline: none;
    border: none;

    &.reset-password {
      margin-right: 0.2rem;
    }

    &.agreement,
    &.register-to-login {
      color: rgba(0, 122, 255, 1);
      padding: 0;
    }

    &:hover {
      color: rgba(0, 122, 255, 1);
    }
  }
}