.register-box {
  @import "Login";

  .login-type {
	margin: 0 0.4rem;

	a {
	  margin: 0;
	}
  }

  .form-box {
	margin: 0 0.4rem;

	.ant-alert-message {
	  font-family: Arial;
	  font-size: 0.2rem;
	}

	.login-code {
	  .code-button {
		height: 0.8rem;
		line-height: unset;
	  }
	}

	.input-field {
	  height: 0.8rem;
	}

	.login-button {
	  margin-top: 0.12rem;
	}

	.login-redirect {
	  display: flex;
	  justify-content: space-between;
	  align-items: center;

	  span {
		&:first-child {
		  input {
			vertical-align: middle;
		  }

		  label {
			vertical-align: middle;
		  }
		}

		&:last-child {
		  vertical-align: middle;
		}
	  }
	}
  }
}
@primary-color: #007aff;