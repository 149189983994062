.important-alert {
  .ant-modal {
	width: 12.4rem !important;
  }

  .alert-content {
	& > div {
	  width: 100%;
	}

	.alert-title {
	  font-weight: 600;
	  font-size: 0.5rem;
	}

	.alert-text {
	  font-size: 0.4rem;

	  a {
		color: rgb(12, 155, 213)
	  }
	}

	.alert-checkbox {
	  margin-top: 1.3rem;
	  margin-bottom: 0.3rem;
	  display: flex;
	  align-items: center;

	  #important-alert-checkbox {
		position: absolute;
		height: 0.3rem;
		width: 0.3rem;
		z-index: 2;
		opacity: 0;
		cursor: pointer;

		&:checked {
		  & ~ span {
			&:before {
			  font-family: sans-serif;
			  content: "\2714";
			  border-color: rgb(112, 182, 3);
			  color: rgb(112, 182, 3);
			}
		  }

		  & ~ label {
			color: rgb(112, 182, 3);
		  }
		}

		& ~ span {
		  margin-top: 0.04rem;

		  &:before {
			font-family: sans-serif;
			color: black;
			content: "";
			height: 0.3rem;
			width: 0.3rem;
			border: 1px solid black;
			display: block;
			text-align: center;
			line-height: 0.27rem;
			font-size: 0.3rem;
			margin-right: 0.1rem;
		  }
		}

		& ~ label {
		  color: black;
		  font-size: 0.4rem;
		  cursor: pointer;
		}
	  }
	}

	.alert-button {
	  .btn {
		background-color: rgb(34, 119, 218);
		cursor: pointer;
		width: 4rem;
		margin: auto;
		height: 0.8rem;
		text-align: center;
		border-radius: 0.08rem;
		color: white;
		display: block;

		&:disabled,
		&.disabled {
		  background-color: gray;
		  cursor: not-allowed;
		}
	  }
	}
  }
}
@primary-color: #007aff;