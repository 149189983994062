
.top-type {
  text-align: right;

  img {
	width: 1.12rem;
	height: 1.12rem;
	visibility: hidden;
  }
}

.login-type {
  font-size: 0.36rem;
  line-height: 0.8rem;
  margin: 0.6rem 0.8rem 0 0.8rem;

  button {
	border: unset;
	background: unset;
	margin: 0 0.4rem;
	color: rgba(0, 0, 0, 1);
	opacity: 0.55;
	cursor: pointer;

	&:first-child {
	  margin-left: 0;
	}

	&:last-child {
	  margin-right: 0;
	}

	&:active,
	&:focus {
	  color: rgba(0, 0, 0, 1);
	}

	&.active {
	  font-size: 0.6rem;
	  color: rgba(0, 0, 0, 1);
	  opacity: 1;
	  cursor: default;
	}
  }
}

.login-fields {
  margin-top: 0.5rem;
}

