.retrieve-password-box {
  @import "Login";

  .login-type {
	margin-top: 0.3rem;
  }

  .form-box {
	margin: 0 0.8rem;

	.code-button {
	  height: 1.12rem;
	}

	.login-button {
	  margin-top: 0.12rem;
	}
  }
}
@primary-color: #007aff;